import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import styled from "styled-components"
import Head from "../components/Head/Head"

interface TemplateProps {
  data: {
    post: {
      slug: string
      frontmatter: {
        title: string
        subTitle: string
      }
      body: string
    }
  }
}

const ArticleStyles = styled.section`
  h1 + p {
    font-size: clamp(2.6rem, 4vw, 3.8rem);
    line-height: 1.1;
    padding: 1rem 0 2rem;
  }

  .gatsby-resp-image-wrapper {
    margin: 40px 0;
  }
`

const PriciplesTemplate = ({ data }: TemplateProps) => {
  const { post } = data

  return (
    <>
      <Head title={post.frontmatter.title} />
      <ArticleStyles>
        <article className="container">
          <h1>{post.frontmatter.title}</h1>
          <p>{post.frontmatter.subTitle}</p>
          <MDXRenderer>{post.body}</MDXRenderer>
          <Link to="/guiding-principles" className="btn btn-right"> Back to guiding principles</Link>
        </article>
      </ArticleStyles>
    </>
  )
}

export const query = graphql`
  query PostQuery($slug: String!) {
    post: mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        subTitle
      }
      id
      body
    }
  }
`

export default PriciplesTemplate
